import React, { FC, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import googleSvg from "images/Google.svg";
import { Link, useNavigate } from "react-router-dom";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { translations as tr } from "utils/TranslationService";
import { LanguageContext } from "context/LanguageProvider";
import { useForm } from "hooks/useForm";
import { startGoogleSignIn, startLoginWithEmailAndPassword } from "store/auth/thunks";
import { setItem } from "utils/localStorage";


export interface PageLoginProps {
  className?: string;
}


const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const { status } = useSelector((state: any) => state.auth);
  const isAuthenticating = useMemo(() => status === 'checking', [status]);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { translate } = useContext(LanguageContext);

  const { email, password, onInputChange, formState } = useForm({
    email: '',
    password: '',
    status: 'authenticated'
  });



  const signIn = async () => {
    dispatch(startLoginWithEmailAndPassword(email, password));
    navigate('/');
    setItem('auth', { email, password, status });
  };
  const googleSignIn = () => {
    dispatch(startGoogleSignIn());
    navigate('/');
    setItem('auth', formState);
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">

        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {translate(tr.Login) || translate(tr.TraduccionInvalida)}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            <ButtonPrimary
              disabled={isAuthenticating}
              onClick={googleSignIn}
              className="disabled:opacity-50 nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 enabled:hover:translate-y-[-2px]"
            >
              <img
                className="flex-shrink-0"
                src={googleSvg}
                alt={translate(tr.ContinueWithGoogle) || translate(tr.TraduccionInvalida)}
                width={22}
                height={22}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {translate(tr.ContinueWithGoogle) || translate(tr.TraduccionInvalida)}
              </h3>
            </ButtonPrimary>
          </div>

          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {translate(tr.Or) || translate(tr.TraduccionInvalida)}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={signIn}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {translate(tr.EmailAddress) || translate(tr.TraduccionInvalida)}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name='email'
                value={email}
                onChange={onInputChange}


              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {translate(tr.Password) || translate(tr.TraduccionInvalida)}
                <Link to="/forgot-pass" className="text-sm">
                  {translate(tr.ForgotPassword) || translate(tr.TraduccionInvalida)}
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                name="password"
                value={password}
                onChange={onInputChange}
              />
            </label>
            {
              (email && password)
              &&
              <ButtonPrimary
                disabled={isAuthenticating}
                type="submit"
                className="disabled:opacity-50"
                onClick={() => signIn()}
              >
                {translate(tr.Continue) || translate(tr.TraduccionInvalida)}
              </ButtonPrimary>
            }
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {translate(tr.NewUser) || translate(tr.TraduccionInvalida)} {` `}
            <Link to="/signup"> {translate(tr.CreateAnAccount) || translate(tr.TraduccionInvalida)}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
