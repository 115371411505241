import { useContext } from 'react';
import { PathName } from "routers/types";
import { IconFour, IconOne } from "./icons";
import { LanguageContext } from "context/LanguageProvider";
import { translations as tr } from 'utils/TranslationService';
import { DropdownContext, Option } from 'context/DropdownContext';

interface SolutionItem {
    name: string;
    description: string;
    href: PathName;
    icon: any;
    active?: boolean;
    optionName: Option
}

export const Solutions = () => {

    const { translate } = useContext(LanguageContext);
    const { currentOption } = useContext(DropdownContext);

    const solutions: SolutionItem[] = [
        {
            name: translate(tr.Stays),
            optionName: 'Stays',
            description: translate(tr.StaysRentalDescription),
            href: "/listing-stay",
            icon: IconFour,
            active: (currentOption === 'Stays') ? true : false,
        },

        {
            name: translate(tr.ThingsToDo),
            optionName: 'ThingsToDo',
            description: translate(tr.TourAndExperiences),
            href: "/listing-experiences",
            icon: IconOne,
            active: (currentOption === 'ThingsToDo') ? true : false,
        },

    ];
    return solutions;
}

