import React, { useContext } from 'react'
import { LanguageContext, LngType } from 'context/LanguageProvider';
import { translations as tr } from 'utils/TranslationService';

type AdditionalInfoSectionType = {
    title: LngType;
    title2: LngType;
    refundPolicy1: LngType;
    refundPolicy2: LngType;
    title3: LngType;
    listItem1: LngType;
    listItem2: LngType;

}

const additionalInfoSection: AdditionalInfoSectionType = {
    title: {
        en: "Things to know",
        es: "Cosas a Saber",
        pt: "Coisas a Saber"
    },
    title2: {
        en: "Cancellation policy",
        es: "Política de cancelación",
        pt: "Política de cancelamento"
    },
    refundPolicy1: {
        en: "Refund 50% of the booking value when customers cancel the room within 48 hours after successful booking and 14 days before the check-in time.",
        es: "Reembolsar el 50% del valor de la reserva cuando los clientes cancelen la habitación dentro de las 48 horas posteriores a la reserva exitosa y 14 días antes de la hora de check-in.",
        pt: "Reembolsar 50% do valor da reserva quando os clientes cancelarem o quarto dentro de 48 horas após a reserva bem-sucedida e 14 dias antes do horário de check-in."
    },
    refundPolicy2: {
        en: "Then, cancel the room 14 days before the check-in time, get a 50% refund of the total amount paid (minus the service fee).",
        es: "Luego, si cancela la habitación 14 días antes de la hora de check-in, obtendrá un reembolso del 50% del monto total pagado (menos la tarifa de servicio).",
        pt: "Em seguida, cancele o quarto 14 dias antes do horário de check-in e receba um reembolso de 50% do valor total pago (descontada a taxa de serviço)."
    },
    title3: {
        en: "Special Note",
        es: "Nota Especial",
        pt: "Nota Especial",
    },
    listItem1: {
        en: "Ban and I will work together to keep the landscape and environment green and clean by not littering, not using stimulants, and respecting people around.",
        es: "Ban y yo trabajaremos juntos para mantener el paisaje y el entorno verde y limpio, no arrojando basura, no usando estimulantes y respetando a las personas que nos rodean.",
        pt: "Ban e eu trabalharemos juntos para manter a paisagem e o ambiente verde e limpo, não jogando lixo, não usando estimulantes e respeitando as pessoas ao redor.",
    },
    listItem2: {
        en: "Do not sing karaoke past 11:30",
        es: "No cantes karaoke después de las 11:30",
        pt: "Não cante karaoke após as 11:30",
    },
}

export const AdditionalInfoSection = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);
    return (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <h2 className="text-2xl font-semibold">{additionalInfoSection.title[currentLanguage] || translate(tr.TraduccionInvalida)}</h2>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            <div>

                <h4 className="text-lg font-semibold">{additionalInfoSection.title2[currentLanguage] || translate(tr.TraduccionInvalida)}</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    {additionalInfoSection.refundPolicy1[currentLanguage] || translate(tr.TraduccionInvalida)}
                    <br />
                    <br />
                    {additionalInfoSection.refundPolicy2[currentLanguage] || translate(tr.TraduccionInvalida)}

                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            <div>
                <h4 className="text-lg font-semibold">{translate(tr.HorarioDeCheckIn) || translate(tr.TraduccionInvalida)}</h4>

                <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                    <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                        <span>Check-in</span>
                        <span>08:00 am - 12:00 am</span>
                    </div>
                    <div className="flex space-x-10 justify-between p-3">
                        <span>Check-out</span>
                        <span>02:00 pm - 04:00 pm</span>
                    </div>
                </div>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            <div>
                <h4 className="text-lg font-semibold">{additionalInfoSection.title3[currentLanguage] || translate(tr.TraduccionInvalida)}</h4>
                <div className="prose sm:prose">
                    <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
                        <li>
                            {additionalInfoSection.listItem1[currentLanguage] || translate(tr.TraduccionInvalida)}
                        </li>
                        <li>{additionalInfoSection.listItem2[currentLanguage] || translate(tr.TraduccionInvalida)}</li>

                    </ul>
                </div>
            </div>
        </div>
    );
}
