import React, { FC, useContext } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FormStateType, useForm } from "hooks/useForm";
import { ContactInfo as info } from "./contactInfo";
import { translations as tr } from "utils/TranslationService";
import { LanguageContext, LngType } from "context/LanguageProvider";

interface PageContactProps {
  className?: string;
}
type PageContactType = {
  title: LngType;
  socials: LngType;
  fullName: LngType;
  phone: LngType;
  message: LngType;
  fullNamePH: LngType;
  phonePH: LngType;
  messagePH: LngType;
  sendMessageButton: LngType;
}

const initialState: FormStateType = {
  name: '',
  phone: '',
  message: '',
}


const pageContact: PageContactType = {
  title: {
    en: "Contact",
    es: "Contacto",
    pt: "Contato",
  },
  socials: {
    en: "Socials",
    es: "Redes Sociales",
    pt: "Redes Sociais",
  },
  fullName: {
    en: "Full Name",
    es: "Nombre completo",
    pt: "Nome completo",
  },
  phone: {
    en: "Phone number",
    es: "Número de teléfono",
    pt: "Número de telefone",
  },
  message: {
    en: "Message",
    es: "Mensaje",
    pt: "Mensagem",
  },
  fullNamePH: {
    en: "Write your name",
    es: "Escribe tu nombre",
    pt: "Escreva seu nome",
  },
  phonePH: {
    en: "Write your phone number",
    es: "Escribe tu número de teléfono",
    pt: "Escreva seu número de telefone",
  },
  messagePH: {
    en: "Leave us your message",
    es: "Déjanos tu mensaje",
    pt: "Deixe-nos sua mensagem",
  },

  sendMessageButton: {
    en: "Send message",
    es: "Enviar mensaje",
    pt: "Enviar mensagem",
  },
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const { translate, currentLanguage } = useContext(LanguageContext);

  const { onInputChange, formState } = useForm(initialState);

  const { name, message, phone } = formState;

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>{pageContact.title[currentLanguage] || translate(tr.TraduccionInvalida)}</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {pageContact.title[currentLanguage] || translate(tr.TraduccionInvalida)}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info().map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 {pageContact.socials[currentLanguage] || translate(tr.TraduccionInvalida)}
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>{pageContact.fullName[currentLanguage] || translate(tr.TraduccionInvalida)}</Label>

                  <Input
                    placeholder={pageContact.fullNamePH[currentLanguage] || translate(tr.TraduccionInvalida)}
                    type="text"
                    className="mt-1"
                    value={name}
                    name="name"
                    onChange={onInputChange}
                    maxLength={150}
                  />
                </label>
                <label className="block">
                  <Label>{pageContact.phone[currentLanguage] || translate(tr.TraduccionInvalida)}</Label>
                  <Input
                    placeholder={pageContact.phonePH[currentLanguage] || translate(tr.TraduccionInvalida)}
                    className="mt-1"
                    value={phone}
                    name='phone'
                    onChange={onInputChange}
                    maxLength={150}
                  />
                </label>
                <label className="block">
                  <Label>{pageContact.message[currentLanguage] || translate(tr.TraduccionInvalida)}</Label>

                  <Textarea
                    placeholder={pageContact.messagePH[currentLanguage] || translate(tr.TraduccionInvalida)}
                    className="mt-1"
                    rows={6}
                    value={message}
                    name="message"
                    onChange={onInputChange}
                    maxLength={300}
                  />
                </label>
                {(name && phone && message) &&
                  <div>
                    <a href={`mailto:${info()[1].desc}?subject=${name}-${phone}&body=${message}`}
                      target="_blank" rel="noreferrer">
                      <ButtonPrimary>
                        {pageContact.sendMessageButton[currentLanguage] || translate(tr.TraduccionInvalida)}

                      </ButtonPrimary>
                    </a>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
