import React, { useContext } from 'react'
import { LanguageContext, LngType } from 'context/LanguageProvider';
import { translations as tr } from 'utils/TranslationService';

type AboutPlaceSectionType = {
    description1: LngType;
    description2: LngType;
    stayInfo: LngType;
}

const aboutPlaceSection: AboutPlaceSectionType = {
    description1: {
        en: "Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation, an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities. Complimentary WiFi is provided.",
        es: "Ofreciendo vistas al lago, The Symphony 9 Tam Coc en Ninh Binh ofrece alojamiento, una piscina al aire libre, un bar, una sala común, un jardín y instalaciones para hacer barbacoas. Se proporciona WiFi gratuito.",
        pt: "Oferecendo vistas para o lago, The Symphony 9 Tam Coc em Ninh Binh oferece acomodações, uma piscina ao ar livre, um bar, uma sala de estar compartilhada, um jardim e instalações para churrasco. WiFi gratuito é fornecido."
    },
    description2: {
        en: "There is a private bathroom with bidet in all units, along with a hairdryer and free toiletries.",
        es: "En todas las unidades, hay un baño privado con bidé, además de un secador de pelo y artículos de tocador gratuitos.",
        pt: "Todas as unidades têm um banheiro privativo com bidê, além de um secador de cabelo e produtos de higiene pessoal gratuitos."
    },
    stayInfo: {
        en: "Stay information",
        es: "Acerca de la estadía",
        pt: "Informações da estadia"
    }
}


export const AboutPlaceSection = () => {
    const { translate, currentLanguage } = useContext(LanguageContext);

    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">{aboutPlaceSection.stayInfo[currentLanguage] || translate(tr.TraduccionInvalida)}</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300">
                <span>
                    {aboutPlaceSection.description1[currentLanguage] || translate(tr.TraduccionInvalida)}
                </span>
                <br />
                <br />
                <span>
                    {aboutPlaceSection.description2[currentLanguage] || translate(tr.TraduccionInvalida)}
                </span>
                <br /> <br />
                <span>
                    {aboutPlaceSection.description1[currentLanguage] || translate(tr.TraduccionInvalida)}

                </span>
            </div>
        </div>
    );
};
