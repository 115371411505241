import { useContext } from "react";
import { LanguageContext, LngType } from "context/LanguageProvider";
import { translations as tr } from "utils/TranslationService";


type ContactInfoType = {
    address: LngType;
    email: LngType;
    phone: LngType;
}

const contactInfo: ContactInfoType = {
    address: {
        en: "Address",
        es: "Dirección",
        pt: "Endereço",
    },
    email: {
        en: "Email",
        es: "Correo electrónico",
        pt: "E-mail",
    },
    phone: {
        en: "Phone",
        es: "Teléfono",
        pt: "Telefone",
    },
}

export const ContactInfo = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);
    const info = [
        {
            title: `🗺 ${contactInfo.address[currentLanguage] || translate(tr.TraduccionInvalida)}`,
            desc: "Pinamar, Buenos Aires, Argentina",
        },
        {
            title: `💌 ${contactInfo.email[currentLanguage] || translate(tr.TraduccionInvalida)}`,
            desc: "destinopinamarok@gmail.com",
        },
        {
            title: `☎ ${contactInfo.phone[currentLanguage] || translate(tr.TraduccionInvalida)}`,
            desc: "+54 11 3886 0680",
        },
    ];
    return info;
}