import { createContext, useState } from "react";
import { TupleToUnion } from "./LanguageProvider";

export type optionsType = ['Stays', 'Flights', 'ThingsToDo', 'Cars', undefined, '']
const availableOptions: optionsType = ['Stays', 'Flights', 'ThingsToDo', 'Cars', undefined, '']
export type Option = TupleToUnion<typeof availableOptions>;

type DropdownContextType = {
    currentOption: Option
    setCurrentDropdownOption: (option: Option) => void

}

type DropdownProviderType = {
    children: JSX.Element;
}

const defaultValue: DropdownContextType = {
    currentOption: undefined,
    setCurrentDropdownOption: () => { }
}

export const DropdownContext = createContext(defaultValue);

export const DropdownProvider = ({ children }: DropdownProviderType) => {
    const [currentDropdownOption, setCurrentDropdownOption] = useState<Option>();

    const value: DropdownContextType = {
        currentOption: currentDropdownOption,
        setCurrentDropdownOption
    }
    return <DropdownContext.Provider value={value}>
        {children}
    </DropdownContext.Provider>
}