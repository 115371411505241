import { SocialType } from "shared/SocialsShare/SocialsShare";
import { FC } from "react";
import { Icon } from "components/Icons/Icon";
import { logoFacebook, logoTiktok, logoInstagram } from "ionicons/icons";
export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  { name: "Facebook", logo: <Icon iconName={logoFacebook} />, href: "https://www.facebook.com/destinopinamarok" },
  { name: "Tik Tok", logo: <Icon iconName={logoTiktok} />, href: "https://www.tiktok.com/@destinopinamarok" },
  { name: "Instagram", logo: <Icon iconName={logoInstagram} />, href: "https://instagram.com/destinopinamarok" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        target="_blank"
        rel="noreferrer"
      >
        {/* <i className={item.icon}></i> */}
        {item.logo}
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
