import React, { useContext } from 'react';
import { LanguageContext, LngType } from 'context/LanguageProvider';
import { translations as tr } from 'utils/TranslationService';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { useModal } from 'hooks/useModal';
import { AmenitiesArray } from '../constant';
import { RenderModalAmenities } from 'containers/ListingDetailPage/(components)/RenderModalAmenities';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { minimizeFirstLetter } from 'utils/minimizeFirstLetter';

type AmenitiesSectionType = {
    title: LngType;
    showMore: LngType;
    amenitiesWord: LngType;
}

const amenitiesSection: AmenitiesSectionType = {
    title: {
        en: "About the property's amenities and services",
        es: "Acerca de las comodidades y servicios de la propiedad",
        pt: "Sobre as comodidades e serviços da propriedade"
    },
    showMore: {
        en: "View more 20 ",
        es: "Ver más 20 ",
        pt: "Ver mais 20 "
    },
    amenitiesWord: {
        en: "Amenities",
        es: "Comodidades",
        pt: "Comodidades",
    },


}

export const AmenitiesSection = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);

    const { openModalAmenities } = useModal();

    return (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">{amenitiesSection.amenitiesWord[currentLanguage] || translate(tr.TraduccionInvalida)} </h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {amenitiesSection.title[currentLanguage] || translate(tr.TraduccionInvalida)}

                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* 6 */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                {AmenitiesArray

                    .filter((_, i) => i < 12)
                    .map((item) => (
                        <div key={item.name} className="flex items-center space-x-3">
                            <i className={`text-3xl las ${item.icon}`}></i>
                            <span className=" ">{capitalizeFirstLetter(translate(item.title))}</span>

                        </div>
                    ))}
            </div>

            {/* ----- */}
            <div className="w-14 border-b border-neutral-200"></div>
            <div>
                <ButtonSecondary onClick={openModalAmenities}>
                    {`${minimizeFirstLetter(amenitiesSection.showMore[currentLanguage]) || translate(tr.TraduccionInvalida)} 20 ${amenitiesSection.amenitiesWord[currentLanguage] || translate(tr.TraduccionInvalida)}`}

                </ButtonSecondary>
            </div>
            <RenderModalAmenities />
        </div>
    );
}
