import { Icon } from "components/Icons/Icon";
import { logoFacebook, logoInstagram, logoTiktok } from "ionicons/icons";
import React, { FC } from "react";

export interface SocialsShareProps {
  className?: string;
  itemClass?: string;
}

export interface SocialType {
  name: string;
  icon?: string;
  href: string;
  logo?: any;
}

const socials: SocialType[] = [
  { name: "Facebook", logo: <Icon iconName={logoFacebook} />, href: "https://www.facebook.com/destinopinamarok" },
  { name: "Tik Tok", logo: <Icon iconName={logoTiktok} />, href: "https://www.tiktok.com/@destinopinamarok" },
  { name: "Instagram", logo: <Icon iconName={logoInstagram} />, href: "https://instagram.com/destinopinamarok" },
];

const SocialsShare: FC<SocialsShareProps> = ({
  className = "grid gap-[6px]",
  itemClass = "w-7 h-7 text-base hover:bg-neutral-100",
}) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        key={index}
        href={item.href}
        className={`rounded-full leading-none flex items-center justify-center bg-white text-neutral-6000 ${itemClass}`}
        title={`Share on ${item.name}`}
      >
        {item.logo}
      </a>
    );
  };

  return (
    <div className={`nc-SocialsShare ${className}`} data-nc-id="SocialsShare">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsShare;
