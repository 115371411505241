import { FC, useMemo, useContext } from "react";
import googleSvg from "images/Google.svg";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate,/* useNavigation*/ } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startGoogleSignIn, startRegisterWithEmailAndPassword } from "store/auth/thunks";
import { LanguageContext } from "context/LanguageProvider";
import { useForm } from "hooks/useForm";
import { translations as tr } from "utils/TranslationService";

export interface PageSignUpProps {
  className?: string;
}

const formValidations = {
  email: [(value: string) => value.includes('@'), 'El correo debe tener @'],
  password: [(value: string) => value.length >= 6, 'El password debe tener mas de 6 letras'],
  displayName: [(value: string) => value.length >= 1, 'El display name es obligatorio']
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const dispatch = useDispatch<any>();
  const { status } = useSelector((state: any) => state.auth);
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();

  const isAuthenticating = useMemo(() => status === 'checking', [status])
  const { displayName, email, password, onInputChange } = useForm({
    email: '',
    password: '',
    displayName: ''
  });

  const signUp = () => {
    dispatch(startRegisterWithEmailAndPassword(email, password));
    navigate('/login');
  }

  const googleSignIn = () => {
    dispatch(startGoogleSignIn());
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {translate(tr.Signup)}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            <ButtonPrimary
              onClick={googleSignIn}
              className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              disabled={isAuthenticating}
            >
              <img
                className="flex-shrink-0"
                src={googleSvg}
                alt={'Google Sign in'}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {translate(tr.ContinueWithGoogle)}
              </h3>
            </ButtonPrimary>
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {translate(tr.Or)}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {translate(tr.DisplayName)}
              </span>
              <Input
                type="text"
                placeholder="Example Name"
                className="mt-1"
                name="displayName"
                value={displayName}
                onChange={onInputChange}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {translate(tr.EmailAddress)}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                value={email}
                onChange={onInputChange}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {translate(tr.Password)}
              </span>
              <Input
                type="password"
                className="mt-1"
                name="password"
                value={password}
                onChange={onInputChange}
              />
            </label>
            {
              (email && password && displayName)
              &&
              <ButtonPrimary
                type="submit"
                disabled={isAuthenticating}
                onClick={signUp}
              >
                {translate(tr.Continue)}
              </ButtonPrimary>
            }
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {translate(tr.AlreadyHaveAnAccount)} {` `}
            <Link to="/login">{translate(tr.SignIn)}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
