import React, { useContext } from 'react'
import LikeSaveBtns from 'components/LikeSaveBtns';
import StartRating from 'components/StartRating/StartRating';
import { LanguageContext, LngType } from 'context/LanguageProvider';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import { translations as tr } from 'utils/TranslationService';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

type PlaceGeneralInfoSectionType = {
    title: LngType;
    guests: LngType;
    hostedBy: LngType;
    placeLocationName: LngType;
    beds: LngType;
    bathrooms: LngType;
    rooms: LngType;
    reserve: LngType;

    hosterName: string;
}

const placeGeneralInfo: PlaceGeneralInfoSectionType = {
    hosterName: 'Kevin Franciss',
    title: {
        en: "Beach House in Collingwood",
        es: "Casa de playa en Collingwood",
        jt: "Casa de praia em Collingwood"
    },
    guests: {
        en: "Guests",
        es: "Huéspedes",
        pt: "Hóspedes"
    },
    hostedBy: {
        en: "Hosted by",
        es: "Publicado por",
        pt: "Hospedado por"
    },
    placeLocationName: {
        en: "Tokyo, Japan",
        es: "Tokio, Japón",
        pt: "Tóquio, Japão"
    },
    beds: {
        en: "Beds",
        es: "Camas",
        pt: "Camas"
    },
    bathrooms: {
        en: "Baths",
        es: "Baños",
        pt: "Banheiros"
    },
    rooms: {
        en: "Bedrooms",
        es: "Habitaciones",
        pt: "Quartos"
    },
    reserve: {
        en: "Reserve",
        es: "Reservar",
        pt: "Reservar",
    },
};

export const PlaceGeneralInfoSection = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);

    return (
        <div className="listingSection__wrap !space-y-6">
            {/* 1 */}
            <div className="flex justify-between items-center">
                <Badge name="Wooden house" />
                <LikeSaveBtns />
            </div>

            {/* 2 */}
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {/* {translate(tr.CasaDePlayaEnCollingwood)} */}
                {placeGeneralInfo.title[currentLanguage] || translate(tr.TraduccionInvalida)}
            </h2>

            {/* 3 */}
            <div className="flex items-center space-x-4">
                <StartRating />
                <span>·</span>
                <span>
                    <i className="las la-map-marker-alt"></i>
                    <span className="ml-1">  {placeGeneralInfo.placeLocationName[currentLanguage] || translate(tr.TraduccionInvalida)}</span>
                </span>
            </div>

            {/* 4 */}
            <div className="flex items-center">
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
                <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                    {placeGeneralInfo.hostedBy[currentLanguage] || translate(tr.TraduccionInvalida)}{" "}
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                        {placeGeneralInfo.hosterName}
                    </span>
                </span>
            </div>

            {/* 5 */}
            <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

            {/* 6 */}
            <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                <div className="flex items-center space-x-3 ">
                    <i className=" las la-user text-2xl "></i>
                    <span className="">
                        {/* TODO: Variable tipo number para cantidad de cantidad de huespedes */}
                        6 <span className="hidden sm:inline-block">{placeGeneralInfo.guests[currentLanguage] || translate(tr.TraduccionInvalida)}</span>
                    </span>
                </div>
                <div className="flex items-center space-x-3">
                    <i className=" las la-bed text-2xl"></i>
                    <span className=" ">
                        {/* TODO: Variable tipo number para cantidad de cantidad de camas */}
                        6 <span className="hidden sm:inline-block">{placeGeneralInfo.beds[currentLanguage] || translate(tr.TraduccionInvalida)}</span>
                    </span>
                </div>
                <div className="flex items-center space-x-3">
                    <i className=" las la-bath text-2xl"></i>
                    <span className=" ">
                        {/* TODO: Variable tipo number para cantidad de cantidad de baños */}
                        3 <span className="hidden sm:inline-block">{placeGeneralInfo.bathrooms[currentLanguage] || translate(tr.TraduccionInvalida)}</span>
                    </span>
                </div>
                <div className="flex items-center space-x-3">
                    <i className=" las la-door-open text-2xl"></i>
                    <span className=" ">
                        {/* TODO: Variable tipo number para cantidad de cantidad de habitaciones */}
                        2 <span className="hidden sm:inline-block">{placeGeneralInfo.rooms[currentLanguage] || translate(tr.TraduccionInvalida)}</span>
                    </span>
                </div>
            </div>
            <ButtonPrimary href={"/checkout"}>{placeGeneralInfo.reserve[currentLanguage] || translate(tr.TraduccionInvalida)}</ButtonPrimary>
        </div>
    );
};