import { useState } from 'react'

export const useModal = () => {
    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

    const closeModalAmenities = () => {
        setIsOpenModalAmenities(false);
    }

    const openModalAmenities = () => {
        setIsOpenModalAmenities(true);
    }


    return {
        closeModalAmenities,
        openModalAmenities,
        isOpenModalAmenities
    }
}
