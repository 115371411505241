import React, { useContext } from 'react'
import { LanguageContext, LngType } from 'context/LanguageProvider';
import { translations as tr } from 'utils/TranslationService';

type LocationSectionType = {

    place: LngType;
}
const locationSection: LocationSectionType = {

    place: {
        en: "San Diego, CA, United States of America (SAN-San Diego Intl.)",
        es: "San Diego, CA, Estados Unidos de América (SAN-San Diego Intl.)",
        pt: "San Diego, CA, Estados Unidos da América (SAN-San Diego Intl.)",
    },

}

export const LocationSection = () => {
    const { translate, currentLanguage } = useContext(LanguageContext);


    return (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <div>
                <h2 className="text-2xl font-semibold">{translate(tr.Location) || translate(tr.TraduccionInvalida)}</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {locationSection.place[currentLanguage] || translate(tr.TraduccionInvalida)}

                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* MAP */}
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                <div className="rounded-xl overflow-hidden z-0">
                    <iframe
                        title="x"
                        width="100%"
                        height="100%"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
