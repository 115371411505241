import React from 'react'
import { IonIcon } from '@ionic/react'

type IconType = {
    iconName: string;
    logoSize?: number;
}

export const Icon = ({ iconName, logoSize = 20 }: IconType) => {
    return (
        <>
            <IonIcon icon={iconName} style={{ width: logoSize, height: logoSize }} />

        </>
    )
}
