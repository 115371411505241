import React, { useContext } from 'react';
import { LanguageContext, LngType } from 'context/LanguageProvider';

import Avatar from 'shared/Avatar/Avatar';
import { translations as tr } from 'utils/TranslationService';
import StartRating from 'components/StartRating/StartRating';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

type HosterInfoSectionType = {
    description: LngType;
    responseRate: LngType;
    fastResponse: LngType;
    timeOfResponse: LngType;
    hostProfileButtonText: LngType;
    unidoEn: LngType;
    mes: LngType;
}

const hosterInfoSection: HosterInfoSectionType = {
    description: {
        en: "Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation, an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities. Complimentary WiFi is provided.",
        es: "Ofreciendo vistas al lago, The Symphony 9 Tam Coc en Ninh Binh ofrece alojamiento, una piscina al aire libre, un bar, una sala común, un jardín y instalaciones para hacer barbacoas. Se proporciona WiFi gratuito.",
        pt: "Oferecendo vistas para o lago, The Symphony 9 Tam Coc em Ninh Binh oferece acomodações, uma piscina ao ar livre, um bar, uma sala de estar compartilhada, um jardim e instalações para churrasco. WiFi gratuito é fornecido."
    },
    responseRate: {
        en: "Response rate",
        es: "Tasa de respuesta",
        pt: "Taxa de resposta",
    },
    fastResponse: {
        en: "Fast response",
        es: "Respuesta rápida",
        pt: "Resposta rápida",
    },
    timeOfResponse: {
        en: "Within a few hours",
        es: "En unas pocas horas",
        pt: "Dentro de algumas horas",
    },
    hostProfileButtonText: {
        en: "See host profile",
        es: "Ver perfil del anfitrión",
        pt: "Ver perfil do anfitrião",
    },
    unidoEn: {
        en: "Joined in",
        es: "Unido en",
        pt: "Ingressou em"
    },
    mes: {
        en: "March",
        es: "Marzo",
        pt: "Março"
    }
}

export const HosterInfoSection = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);

    return (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <h2 className="text-2xl font-semibold">{translate(tr.InfoDelAnfitrion)}</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            {/* host */}
            <div className="flex items-center space-x-4">
                <Avatar
                    hasChecked
                    hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
                    sizeClass="h-14 w-14"
                    radius="rounded-full"
                />
                <div>
                    <a className="block text-xl font-medium" href="##">
                        {/* TODO: Colocar Usuario como variable */}
                        Kevin Francis
                    </a>
                    <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                        <StartRating />
                        <span className="mx-2">·</span>
                        {/* TODO: variable para la cantidad de hospedajes del usuario */}
                        <span> 12 {translate(tr.Hospedajes)}</span>
                    </div>
                </div>
            </div>

            {/* desc */}
            <span className="block text-neutral-6000 dark:text-neutral-300">
                {hosterInfoSection.description[currentLanguage] || translate(tr.TraduccionInvalida)}

            </span>

            {/* info */}
            <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
                <div className="flex items-center space-x-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                    </svg>
                    <span>{`${hosterInfoSection.unidoEn[currentLanguage] || translate(tr.TraduccionInvalida)} ${hosterInfoSection.mes[currentLanguage] || translate(tr.TraduccionInvalida)}`}  2016</span>

                </div>
                <div className="flex items-center space-x-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                    </svg>

                    <span>
                        {`${hosterInfoSection.responseRate[currentLanguage] || translate(tr.TraduccionInvalida)} - 100%`}
                    </span>

                </div>
                <div className="flex items-center space-x-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>

                    <span>
                        {`${hosterInfoSection.fastResponse[currentLanguage]
                            || translate(tr.TraduccionInvalida)} - 
                        
                        ${hosterInfoSection.timeOfResponse[currentLanguage]
                            || translate(tr.TraduccionInvalida)}`}
                    </span>

                </div>
            </div>

            {/* == */}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
                <ButtonSecondary href="/author">{hosterInfoSection.hostProfileButtonText[currentLanguage] || translate(tr.TraduccionInvalida)}</ButtonSecondary>

            </div>
        </div>
    );
}
