import { Helmet } from "react-helmet";
import Header from "components/Header/Header";

const SiteHeader = () => {
  return (
    <>
      <Helmet>
        <title> Destino Pinamar </title>
      </Helmet>
      <Header className="shadow-sm dark:border-b dark:border-neutral-700" />
      <div className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
