export const translations = {
    LanguageFormat: {
        en: 'en-US',
        es: 'es-ES',
        pt: 'pt-BR'
    },
    TraduccionInvalida: {
        en: "Invalid Translation",
        es: "Traducción Inválida",
        pt: "Tradução Inválida"
    },
    Amenity: {
        LaKey: {
            en: "key",
            es: "llave",
            pt: "chave"
        },
        LaLuggageCart: {
            en: "luggage cart",
            es: "carro de equipaje",
            pt: "carrinho de bagagem"
        },
        LaShower: {
            en: "shower",
            es: "ducha",
            pt: "duche"
        },
        LaSmoking: {
            en: "smoking",
            es: "fumador",
            pt: "fumante"
        },
        LaSnowflake: {
            en: "snowflake",
            es: "copo de nieve",
            pt: "floco de neve"
        },
        LaSpa: {
            en: "spa",
            es: "spa",
            pt: "spa"
        },
        LaSuitcase: {
            en: "suitcase",
            es: "maleta",
            pt: "mala"
        },
        LaSuitcaseRolling: {
            en: "rolling suitcase",
            es: "maleta con ruedas",
            pt: "mala com rodas"
        },
        LaSwimmer: {
            en: "swimmer",
            es: "nadador",
            pt: "nadador"
        },
        LaSwimmingPool: {
            en: "swimming pool",
            es: "piscina",
            pt: "piscina"
        },
        LaTV: {
            en: "TV",
            es: "televisión",
            pt: "TV"
        },
        LaUmbrellaBeach: {
            en: "beach umbrella",
            es: "sombrilla de playa",
            pt: "guarda-sol de praia"
        },
        LaUtensils: {
            en: "utensils",
            es: "utensilios",
            pt: "utensílios"
        },
        LaWheelchair: {
            en: "wheelchair",
            es: "silla de ruedas",
            pt: "cadeira de rodas"
        },
        LaWifi: {
            en: "Wi-Fi",
            es: "Wi-Fi",
            pt: "Wi-Fi"
        },
        LaBabyCarriage: {
            en: "baby carriage",
            es: "cochecito de bebé",
            pt: "carrinho de bebê"
        },
        LaBath: {
            en: "bath",
            es: "bañera",
            pt: "banheira"
        },
        LaBed: {
            en: "bed",
            es: "cama",
            pt: "cama"
        },
        LaBriefcase: {
            en: "briefcase",
            es: "maletín",
            pt: "maleta executiva"
        },
        LaCar: {
            en: "car",
            es: "coche",
            pt: "carro"
        },
        LaCocktail: {
            en: "cocktail",
            es: "cóctel",
            pt: "coquetel"
        },
        LaCoffee: {
            en: "coffee",
            es: "café",
            pt: "café"
        },
        LaConciergeBell: {
            en: "concierge bell",
            es: "timbre de conserje",
            pt: "campainha de concierge"
        },
        LaDice: {
            en: "dice",
            es: "dados",
            pt: "dados"
        },
        LaDumbbell: {
            en: "dumbbell",
            es: "pesa",
            pt: "haltere"
        },
        LaHotTub: {
            en: "hot tub",
            es: "bañera de hidromasaje",
            pt: "banheira de hidromassagem"
        },
        LaInfinity: {
            en: "infinity",
            es: "infinito",
            pt: "infinito"
        }

    },
    ShowMore: {
        en: "Show more",
        es: "Mostrar más",
        pt: "Mostrar mais",
    },
    VivePinamarDiferente: {
        en: "Experience Pinamar differently",
        es: "Vive Pinamar diferente",
        pt: "Viva Pinamar de forma diferente",

    },
    Account: {
        en: "Account",
        es: "Cuenta",
        pt: "Conta",
    },
    Messages: {
        en: "Messages",
        es: "Mensajes",
        pt: "Mensagens",
    },
    Wishlists: {
        en: "Wishlists",
        es: "Listas de deseos",
        pt: "Listas de desejos",
    },
    Booking: {
        en: "Booking",
        es: "Reservas",
        pt: "Reserva",
    },
    Login: {
        en: "Login",
        es: "Iniciar sesión",
        pt: "Entrar",
    },
    Logout: {
        en: "Logout",
        es: "Cerrar sesión",
        pt: "Sair",
    },
    Help: {
        en: "Help",
        es: "Ayuda",
        pt: "Ajuda",
    },
    Or: {
        en: "Or",
        es: "O",
        pt: "Ou",
    },
    EmailAddress: {
        en: "Email address",
        es: "Dirección de correo electrónico",
        pt: "Endereço de e-mail",
    },
    Password: {
        en: "Password",
        es: "Contraseña",
        pt: "Senha",
    },
    Continue: {
        en: "Continue",
        es: "Continuar",
        pt: "Continuar",
    },
    NewUser: {
        en: "New user?",
        es: "¿Nuevo usuario?",
        pt: "Novo usuário?",
    },
    CreateAnAccount: {
        en: "Create an account",
        es: "Crear una cuenta",
        pt: "Criar uma conta",
    },

    ContinueWithGoogle: {
        en: "Continue with Google",
        es: "Continuar con Google",
        pt: "Continuar com o Google",
    },
    Signup: {
        en: "Signup",
        es: "Registro",
        pt: "Cadastro",
    },
    SignIn: {
        en: "Sign in",
        es: "Iniciar sesión",
        pt: "Entrar",
    },
    DisplayName: {
        en: "Username",
        es: "Nombre de usuario",
        pt: "Nome de exibição",
    },
    AlreadyHaveAnAccount: {
        en: "Already have an account?",
        es: "¿Ya tienes una cuenta?",
        pt: "Já tem uma conta?",
    },

    CreateUniqueMemories: {
        en: "Create unique memories in an environment surrounded by forests, nature, sand, and sea.",
        es: "Crea recuerdos únicos en un entorno rodeado de bosques, naturaleza, arena y mar.",
        pt: "Crie memórias únicas em um ambiente cercado por florestas, natureza, areia e mar.",
    },
    Language: {
        en: "Language",
        es: "Idioma",
        pt: "Idioma"
    },
    ForgotPassword: {
        en: "Forgot Password?",
        es: "¿Olvidaste tu contraseña?",
        pt: "Esqueceu a senha?",
    },
    Brazil: {
        en: "Brazil",
        es: "Brasil",
        pt: "Brasil"
    },
    Español: {
        en: "Spanish",
        es: "Español",
        pt: "Espanhol"
    },
    UnitedStates: {
        en: "United States",
        es: "Estados Unidos",
        pt: "Estados Unidos"
    },
    Argentina: {
        en: "Argentina",
        es: "Argentina",
        pt: "Argentina"
    },

    AccommodationsActivities: {
        en: "Accommodations, activities, and tips for your stay in Pinamar, Argentina!",
        es: "¡Alojamientos, actividades y consejos para tu estadía en Pinamar, Argentina!",
        pt: "Alojamentos, atividades e dicas para a sua estadia em Pinamar, Argentina!"
    },
    LugaresDestacadosParaHospedarse: {
        en: "Featured places to stay",
        es: "Lugares destacados para hospedarse",
        pt: "Locais em destaque para se hospedar"
    },
    Location: {
        en: "Location",
        es: "Ubicación",
        pt: "Localização",
    },

    CasaDePlayaEnCollingwood: {
        en: "Beach House in Collingwood",
        es: "Casa de playa en Collingwood",
        pt: "Casa de praia em Collingwood"
    },
    ContactUs: {
        en: "Contact us",
        es: "Contáctenos",
        pt: "Contate-nos",
    },
    Baños: {
        en: "Baths",
        es: "Baños",
        pt: "Banheiros"
    },
    Habitaciones: {
        en: "Bedrooms",
        es: "Habitaciones",
        pt: "Quartos"
    },

    FollowUsForCoastalParadise: {
        en: "Follow us to discover the best accommodations, activities, and tips to make the most of your stay in this coastal paradise! 🌊🌞",
        es: "¡Síguenos para conocer los mejores alojamientos, actividades y consejos para disfrutar al máximo tu estadía en este paraíso costero! 🌊🌞",
        pt: "Siga-nos para descobrir as melhores acomodações, atividades e dicas para aproveitar ao máximo sua estadia neste paraíso à beira-mar! 🌊🌞"
    },

    Comodidades: {
        en: "Amenities",
        es: "Comodidades",
        pt: "Comodidades"
    },

    /*------------------------- HEADER --------------------------- */
    Travelers: {
        en: "Travelers",
        es: "Viajeros",
        pt: "Viajantes"
    },
    TarifasHabitaciones: {
        en: "Room Rates",
        es: "Tarifas de Habitación",
        pt: "Tarifas de Quarto"
    },
    Documentation: {
        en: "Documentation",
        es: "Documentación",
        pt: "Documentação",
    },
    StartIntegratingProductsAndTools: {
        en: "Start integrating products and tools",
        es: "Comienza a integrar productos y herramientas",
        pt: "Comece a integrar produtos e ferramentas",
    },
    /*-------------------------------- Travelers Dropdown -------------------------------- */
    Stays: {
        en: "Stays",
        es: "Estancias",
        pt: "Estadias",
    },
    StaysRentalDescription: {
        en: "Stays rental description",
        es: "Descripción de alquiler de estancias",
        pt: "Descrição de aluguel de estadias",
    },
    Currency: {
        en: "Currency",
        es: "Moneda",
        pt: "Moeda",
    },
    Flights: {
        en: "Flights",
        es: "Vuelos",
        pt: "Voos",
    },
    FlightsDescription: {
        en: "Flights description",
        es: "Descripción de vuelos",
        pt: "Descrição de voos",
    },
    ThingsToDo: {
        en: "Things to do",
        es: "Cosas para hacer",
        pt: "Atividades para fazer",
    },
    TourAndExperiences: {
        en: "Tour and experiences",
        es: "Tours y experiencias",
        pt: "Passeios e experiências",
    },
    Cars: {
        en: "Cars",
        es: "Coches",
        pt: "Carros",
    },
    CarRentalDescription: {
        en: "Car rental description",
        es: "Descripción de alquiler de coches",
        pt: "Descrição de aluguel de carros",
    },
    /*-------------------------------- Travelers Dropdown -------------------------------- */

    ListYourProperty: {
        en: "List your property",
        es: "Lista tu propiedad",
        pt: "Liste sua propriedade",
    },
    /*------------------------- HEADER --------------------------- */

    PricesMayIncreaseOnWeekendsOrHolidays: {
        en: "Prices may increase on weekends or holidays",
        es: "Los precios pueden aumentar los fines de semana o durante días festivos",
        pt: "Os preços podem aumentar nos fins de semana ou feriados"
    },
    MinimoDeNoches: {
        en: "Minimum number of nights",
        es: "Número mínimo de noches",
        pt: "Número mínimo de noites"
    },
    MaximoDeNoches: {
        en: "Max number of nights",
        es: "Número máximo de noches",
        pt: "Número máximo de noites"
    },

    AlquilerPorMes: {
        en: "Rent by month",
        es: "Alquiler por mes",
        pt: "Aluguel por mês"
    },
    Noches: {
        en: "Nights",
        es: "Noches",
        pt: "Noites"
    },
    Noche: {
        en: "Night",
        es: "Noche",
        pt: "Noite"
    },
    InfoDelAnfitrion: {
        en: "Host Information",
        es: "Información del Anfitrión",
        pt: "Informações do Anfitrião"
    },
    Hospedajes: {
        en: "Places",
        es: "Hospedajes",
        pt: "Hospedagens"
    },

    HorarioDeCheckIn: {
        en: "Check-in time",
        es: "Horario de check-in",
        pt: "Horário de check-in"
    },

    Compartir: {
        en: "Share",
        es: "Compartir",
        pt: "Compartilhar"
    },
    Guardar: {
        en: "Save",
        es: "Guardar",
        pt: "Salvar"
    },
    Disponibilidad: {
        en: "Availability",
        es: "Disponibilidad",
        pt: "Disponibilidade"
    },


};
