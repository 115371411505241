import DetailPagetLayout from "../Layout";
import { StayDetailPageContainer } from "./components/StayDetailPageContainer";

const ListingStayDetailPage = () => {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}

export default ListingStayDetailPage;