import React, { useContext } from 'react'
import CommentListing from 'components/CommentListing/CommentListing';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import { LanguageContext, LngType } from 'context/LanguageProvider';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { translations as tr } from 'utils/TranslationService';
import { minimizeFirstLetter } from 'utils/minimizeFirstLetter';

type ReviewsSectionType = {
    reviews: LngType;
    shareYourThoughts: LngType;

}

const reviewsSection: ReviewsSectionType = {
    reviews: {
        en: "Reviews",
        es: "Reseñas",
        pt: "Avaliações",
    },
    shareYourThoughts: {
        en: "Share your thoughts...",
        es: "Comparte tus pensamientos...",
        pt: "Compartilhe seus pensamentos...",
    },
};



export const ReviewsSection = () => {

    const { translate, currentLanguage } = useContext(LanguageContext);
    return (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <h2 className="text-2xl font-semibold">
                {reviewsSection.reviews[currentLanguage] || translate(tr.TraduccionInvalida)} (23 {minimizeFirstLetter(reviewsSection.reviews[currentLanguage] || translate(tr.TraduccionInvalida))}
                (TODO: Colocar variable))</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            {/* Content */}
            <div className="space-y-5">
                <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
                <div className="relative">
                    <Input
                        fontClass=""
                        sizeClass="h-16 px-4 py-3"
                        rounded="rounded-3xl"
                        placeholder={reviewsSection.shareYourThoughts[currentLanguage] || translate(tr.TraduccionInvalida)}

                    />
                    <ButtonCircle
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                        size=" w-12 h-12 "
                    >
                        <ArrowRightIcon className="w-5 h-5" />
                    </ButtonCircle>
                </div>
            </div>

            {/* comment */}
            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
                <CommentListing className="py-8" />
                <CommentListing className="py-8" />
                <CommentListing className="py-8" />
                <CommentListing className="py-8" />
                <div className="pt-8">
                    <ButtonSecondary>View more 20 reviews</ButtonSecondary>
                </div>
            </div>
        </div>
    );
}
