import React, { createContext, useState } from 'react'
import { translations } from 'utils/TranslationService';

export type Languages = ["es", "en", "pt"];
const availableLanguages: Languages = ["es", "en", "pt"];
export type TupleToUnion<T extends any[]> = T[number]
export type Language = TupleToUnion<typeof availableLanguages>;

export type LngType = { [lng: string]: string; }

export type ILanguageContext = {
    currentLanguage: Language,
    setCurrentLanguage: (lang: Language) => void,
    translate: (value: LngType) => string
}

const defaultLanguageContext: ILanguageContext = {
    currentLanguage: 'es',
    setCurrentLanguage: () => 'es',
    translate: () => { return '' }
}

export const LanguageContext = createContext(defaultLanguageContext)

export const LanguageProvider = (props: any) => {
    const [currentLanguage, setCurrentLanguage] = useState<Language>('es')

    const translate = (value: LngType) => value[currentLanguage] || translations.TraduccionInvalida[currentLanguage];

    const value: ILanguageContext = {
        currentLanguage,
        setCurrentLanguage,
        translate
    }

    return (
        <LanguageContext.Provider value={value}>
            {props.children}
        </LanguageContext.Provider>
    )
}
