import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { LanguageContext } from "context/LanguageProvider";

import { translations as tr } from "utils/TranslationService";
import { Solutions } from "./components/solutions";
import { DropdownContext } from "context/DropdownContext";

const DropdownTravelers = () => {

  const { translate } = useContext(LanguageContext);
  const { setCurrentDropdownOption } = useContext(DropdownContext);
  return (
    <div className="DropdownTravelers">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${open ? "" : "text-opacity-90"}
                group py-2 rounded-md text-sm sm:text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div className={` inline-flex items-center `} role="button">
                <span>{translate(tr.Travelers) || translate(tr.TraduccionInvalida)}</span>
                <ChevronDownIcon
                  className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-2 h-5 w-5 text-neutral-700 group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {Solutions().map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        onClick={() => {
                          close()
                          setCurrentDropdownOption(item.optionName)
                        }}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg 
                        hover:bg-neutral-50 dark:hover:bg-neutral-700 focus:outline-none 
                        focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 
                        ${item.active
                            ? "bg-neutral-100 dark:bg-neutral-700"
                            : ""
                          }`}
                      >
                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-primary-50 rounded-md text-primary-500 sm:h-12 sm:w-12">
                          <item.icon aria-hidden="true" />
                        </div>
                        <div className="ml-4 space-y-0.5">
                          <p className="text-sm font-medium ">{item.name}</p>
                          <p className="text-xs text-neutral-500 dark:text-neutral-300">
                            {item.description}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  {/* FOOTER */}
                  <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                    <Link
                      to="##"
                      className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium ">
                          {translate(tr.Documentation) || translate(tr.TraduccionInvalida)}
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500 dark:text-neutral-400">
                        {translate(tr.StartIntegratingProductsAndTools) || translate(tr.TraduccionInvalida)}
                      </span>
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default DropdownTravelers;