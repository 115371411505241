import { useContext } from 'react';
import { translations as tr } from 'utils/TranslationService';
import { LanguageContext } from 'context/LanguageProvider';
import { UserCircleIcon, ChatBubbleBottomCenterTextIcon, HeartIcon, HomeIcon } from '@heroicons/react/24/outline';

export const AvatarSolutions = () => {
    const { translate } = useContext(LanguageContext);

    const solutions = [
        {
            name: (translate(tr.Account) || translate(tr.TraduccionInvalida)),
            href: "/author",
            icon: UserCircleIcon,
        },
        {
            name: (translate(tr.Messages) || translate(tr.TraduccionInvalida)),
            href: "##",
            icon: ChatBubbleBottomCenterTextIcon,
        },
        {
            name: (translate(tr.Wishlists) || translate(tr.TraduccionInvalida)),
            href: "/account-savelists",
            icon: HeartIcon,
        },

        {
            name: (translate(tr.Booking) || translate(tr.TraduccionInvalida)),
            href: "##",
            icon: HomeIcon,
        },
    ];

    return solutions;

}
